<template>
  <div>
    <optionscard :deliverParentNum="1"></optionscard>
    <breadcrumb></breadcrumb>
    <!-- 1200宽度的div（主体区域） -->
    <div class="width1200">
      <!--  -->
      <div class="fourPic">
        <div
          class="onceFourPic"
          v-for="(item, index) in classifyList"
          :key="item.category_id"
          @click="upLeft(item.category_id)"
        >
          <img :src="item.image" width="100%" height="100%" />
          <!-- 阴影 平时display：none  移到up-left时 display：block -->
          <div class="shadow1"></div>
          <!-- 箭头图标 平时display：none  移到up-left时 display：block -->
          <i class="iconfont icon-xiangyou"></i>
          <!-- 右下角 学校避震攻略 -->
          <div v-if="index % 4 === 0 || index % 4 === 3" class="up-left-word">
            <div class="rightLabel">{{ item.name }}</div>
            <!-- 右下角 学校避震攻略下面的蓝色横线-->
            <div class="ul-bluebulk"></div>
          </div>
          <div v-else class="up-right-word">
            <div class="leftLabel">{{ item.name }}</div>
            <!-- 右下角 学校避震攻略下面的蓝色横线-->
            <div class="ur-bluebulk"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import optionscard from '@/components/optionscard.vue'
import breadcrumb from '@/components/Breadcrumb.vue'
export default {
  components: {
    // 头部选项卡（首页 场馆展品 场馆活动 地震知识 参观服务
    optionscard,
    // 面包屑
    breadcrumb
  },
  data () {
    return {
      classifyList: []
    }
  },
  created () {
    this.getPic()
  },
  methods: {
    /* 左上图片点击 跳转 */
    upLeft (id) {
      console.log(id)
      this.$router.push({ path: '/homepage/bzglsecond', query: { id } })
    },

    // 获取图片
    async getPic () {
      const { res } = await this.$api.homepage.getClassifyPic({ pid: 1 })
      console.log('pic', res)
      this.classifyList = res
      console.log('classifyList', this.classifyList)
    }
  }
}
</script>

<style lang="scss" scoped>
.width1200 {
  width: 1200px;
  margin: 0 auto;
}

.fourPic {
  display: flex;
  justify-content: space-between;
  flex-flow: wrap;
  margin-top: 40px;
}

.onceFourPic {
  width: 590px;
  height: 280px;
  cursor: pointer;
  margin-bottom: 70px;
  position: relative;
}

.onceFourPic:hover .shadow1 {
  display: block;
}
.onceFourPic:hover .shadow2 {
  display: block;
}
.onceFourPic:hover .shadow3 {
  display: block;
}
.onceFourPic:hover .shadow4 {
  display: block;
}
.onceFourPic:hover .icon-xiangyou {
  display: block;
}

.onceFourPic:hover .up-left-word {
  color: #2e88e4;
}

.onceFourPic:hover .rightLabel {
  color: #2e88e4;
}

.onceFourPic:hover .leftLabel {
  color: #2e88e4;
}

.bzgl-up {
  display: flex;
  justify-content: space-between;
}

.bzgl-down {
  display: flex;
  justify-content: space-between;
}

.up-left,
.up-right,
.down-left,
.down-right {
  width: 590px;
  height: 280px;
  cursor: pointer;
  position: relative;
}

.up-left:hover .shadow1 {
  display: block;
}

.up-left:hover .icon-xiangyou {
  display: block;
}

.up-left:hover .up-left-word {
  color: #2e88e4;
}

.up-right:hover .shadow2 {
  display: block;
}

.up-right:hover .icon-xiangyou {
  display: block;
}

.up-right:hover .up-right-word {
  color: #2e88e4;
}

.down-left:hover .shadow3 {
  display: block;
}

.down-left:hover .icon-xiangyou {
  display: block;
}

.down-left:hover .down-left-word {
  color: #2e88e4;
}

.down-right:hover .shadow4 {
  display: block;
}

.down-right:hover .icon-xiangyou {
  display: block;
}

.down-right:hover .down-right-word {
  color: #2e88e4;
}
.bzgl-up {
  margin-top: 40px;
}

.bzgl-down {
  margin-top: 70px;
}

.up-left-word {
  width: 233px;
  height: 75px;
  font-size: 25px;
  line-height: 65px;
  background-color: #fff;
  position: absolute;
  right: 0px;
  bottom: -20px;
}

.rightLabel,
.leftLabel {
  margin-left: 27px;
  color: #333333;
}

.ul-bluebulk {
  width: 42px;
  height: 4px;
  background-color: #2e88e4;
  position: absolute;
  right: 160px;
  top: 60px;
  z-index: 999;
}

.up-right-word {
  width: 233px;
  height: 90px;
  font-size: 25px;
  line-height: 65px;
  background-color: #fff;
  position: absolute;
  left: 0px;
  bottom: -20px;
}

.ur-bluebulk {
  width: 42px;
  height: 4px;
  background-color: #2e88e4;
  position: absolute;
  left: 30px;
  top: 60px;
  bottom: 5px;
}

.down-left-word {
  width: 233px;
  height: 75px;
  font-size: 25px;
  line-height: 85px;
  background-color: #fff;
  position: absolute;
  left: 0px;
  bottom: 0px;
}

.dl-bluebulk {
  width: 42px;
  height: 4px;
  background-color: #2e88e4;
  position: absolute;
  left: 30px;
  bottom: 0px;
}

.down-right-word {
  width: 233px;
  height: 75px;
  font-size: 25px;
  line-height: 65px;
  background-color: #fff;
  position: absolute;
  right: 0px;
  bottom: 0px;
}

.dr-bluebulk {
  width: 42px;
  height: 4px;
  background-color: #2e88e4;
  position: absolute;
  right: 160px;
  bottom: 13px;
}

.shadow1,
.shadow2,
.shadow3,
.shadow4 {
  width: 590px;
  height: 280px;
  position: absolute;
  bottom: 0px;
  opacity: 0.14;
  background-color: rgb(240, 240, 240);
  display: none;
}

.icon-xiangyou {
  position: absolute;
  bottom: 110px;
  left: 280px;
  font-size: 44px;
  color: #fff;
  display: none;
}
</style>
